import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  terminalHero,
  terminalTrustedNumbers,
  terminalWhiteGLove,
  cutWires,
  terminalTypesCar,
  terminalVideo,
  terminalSolutionSlides,
  terminalSeloutionsSection,
  terminalTestimonials,
  // terminalIntegrations,
  terminalCta,
  // terminalSuccess,
  // terminalSlides,
  // terminalFAQs,
} from "../../../data/terminal-landing-data";
import Hero from "../../../components/Hero/hero";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import VideoSection from "../../../components/VideoSection/VideoSection";
// import ProductLines from "../../../components/ProductLines/ProductLines";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
import ogImage from "../../../images/global_assets/og-image.png";

const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);
const ProductLines = loadable(() =>
  import("../../../components/ProductLines/ProductLines")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);

const whiteGloveImport = "terminal-white-glove.png";

const Terminal = () => (
  <Layout>
    <SEO
      title="SpotOn Terminal | Payment Terminal"
      description="Run and grow your business easier than ever before with SpotOn Terminal, the all-in-one platform for payments, marketing, and customer insights. Accept all major credit cards and contactless payments with no long-term contract and no hidden fees."
      image={`https://spoton.com/${ogImage}`}
    />
    <Hero sectionData={terminalHero} fatTitle="Terminal" />
    <TrustedNumbers numbersArray={terminalTrustedNumbers} />
    <WhiteGlove
      sectionData={terminalWhiteGLove}
      whiteGloveBg={whiteGloveImport}
    />
    <LargeFeatures sectionData={cutWires} />
    <BusinessTypes sectionData={terminalTypesCar} />
    {/* <SuccessStories sectionData={terminalSuccess} slides={terminalSlides} /> */}
    <VideoSection sectionData={terminalVideo} />
    <ProductLines
      sectionData={terminalSeloutionsSection}
      productSlides={terminalSolutionSlides}
    />
    <TestmonialReviews sectionData={terminalTestimonials} />
    {/* <IntegrationsSection
      sectionData={terminalIntegrations}
      integrationList={restaurantData}
    /> */}
    <LargeCta sectionData={terminalCta} />
    {/* <Faqs sectionData={terminalFAQs} /> */}
  </Layout>
);

export default Terminal;
